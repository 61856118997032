import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useAuth } from "react-oauth2-pkce";
import jwt from "jsonwebtoken";
import { IdToken, IdTokenProvider } from "../../lib/auth-context";
import { RoleEnum, UserContext, UserContextProvider } from "../../lib/user-context";
import { NotAuthorized } from "./NotAuthorized";
import { Loading } from "../../views/Loading";
import { getRoleFromToken } from "../../lib/get-role-from-token"

const Authorized: React.FC<{ role: RoleEnum }> = ({ children, role }) => {
  const { authService } = useAuth();

  const authTokens = authService.getAuthTokens();

  if (!authTokens) {

    authService.authorize();
    return null;
  }

  const idToken1 = jwt.decode(authTokens.id_token);
  let idToken2 = JSON.stringify(idToken1);
  let idToken = JSON.parse(idToken2);

  const isAuthorized = idToken && getRoleFromToken(idToken) === role;

  if (!isAuthorized) {
    return <NotAuthorized />;
  }

  return <IdTokenProvider value={idToken}>{children}</IdTokenProvider>;
};

export type CheckAuthProps = RouteComponentProps<{
  component: any;
  authorizedRole: RoleEnum;
}>;

export const CheckAuth: React.FC<CheckAuthProps> = ({ component: Component, authorizedRole }) => {

  const { authService } = useAuth();
  const isAuthenticated = authService.isAuthenticated();

  if (authService.isPending()) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <NotAuthorized />;
  }

  const authTokens = authService.getAuthTokens();
  const idToken1 = jwt.decode(authTokens.id_token);
  let idToken2 = JSON.stringify(idToken1);
  let idToken = JSON.parse(idToken2);

  if (!idToken) {
    return <NotAuthorized />;
  }

  const user: UserContext = {
    user: {
      isLoggedIn: isAuthenticated,
      role: getRoleFromToken(idToken),
      userName: idToken.nickname,
    },
  };

  return (
    <UserContextProvider value={user}>
      <Authorized role={authorizedRole || RoleEnum.None}>
        <Component />
      </Authorized>
    </UserContextProvider>
  );
};
