import React from "react";
import { PaymentFrequency } from "../../types/globals";

type DefinitionListItem = {
  key?: string;
  value: string | JSX.Element | PaymentFrequency;
};
export type DefinitionListData = DefinitionListItem[];

export type DefinitionListProps = {
  data: DefinitionListData;
  nested?: boolean;
  customClasses?: string;
};

export const DefinitionList: React.FC<DefinitionListProps> = ({
  nested = false,
  data,
  customClasses,
}) => {
  const classNames = nested ? "list__nested-data-list" : "list list--data";
  const itemClassNames = nested ? "list__nested-data-list-item" : "list__item";
  return (
    <ul className={customClasses ? `${customClasses} ${classNames}` : classNames}>
      {data.map(({ key, value }, idx) => (
        <li key={idx} className={itemClassNames}>
          {key && <span>{key}</span>}
          <div className="value">{value}</div>
        </li>
      ))}
    </ul>
  );
};
