import React, {useContext} from "react";
import {FaChevronLeft} from "react-icons/fa";

import {Logo} from "../Logo";
import {userContext} from "@lib/user-context";
import {Link} from "@reach/router";

export const Header: React.FC = () => {
    const {
        user: {isLoggedIn},
    } = useContext(userContext);

    const renderLoggedIn = (
        <div className="col-6 d-flex justify-content-center align-items-center justify-content-md-end">
            <Link to="/logout">
                <button
                    type="button"
                    className="header__nav-link header__logout header__nav-link--logout"
                >
                    Uitloggen
                </button>
            </Link>
        </div>
    )

    const renderNotLoggedIn = (
        <div className="col-6 d-flex justify-content-center align-items-center justify-content-md-end">
            <a href="/" className="text-link text-link--large header__back-button">
                <FaChevronLeft className="chevron-left text-link__icon"/>
                Terug
            </a>
        </div>
    );

    return (
        <header className="header">
            <div className="container">
                <div className="row">
                    <div
                        className="col-6 d-flex justify-content-center justify-content-md-start"
                    >
                        <Logo/>
                    </div>
                    {isLoggedIn ? renderLoggedIn : renderNotLoggedIn}
                </div>
            </div>
        </header>
    );
};
