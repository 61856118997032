import React from "react";
import { Redirect, RouteComponentProps } from "@reach/router";
import { useAuth } from "react-oauth2-pkce";
import jwt from "jsonwebtoken";
import { IdToken } from "./lib/auth-context";
import { RoleEnum } from "./lib/user-context";
import { NotAuthorized } from "./components/CheckAuth/NotAuthorized";
import { Loading } from "./views/Loading";
import { getRoleFromToken } from "./lib/get-role-from-token";

const getPathFromRole = (role: RoleEnum) => {
  switch (role) {
    case RoleEnum.Participant:
      return "klant";
    case RoleEnum.Customer:
      return "klant";
    default:
      return "";
  }
};

export const Authenticate: React.FC<RouteComponentProps> = () => {
  const { authService } = useAuth();

  try {
    if (authService.isPending()) {
      return <Loading authIsPending />;
    }

    if (authService.isAuthenticated()) {
      const authTokens = authService.getAuthTokens();
      const idToken1 = jwt.decode(authTokens.id_token);
      let idToken2 = JSON.stringify(idToken1);
      let idToken = JSON.parse(idToken2);

      if (idToken) {
        const role = getRoleFromToken(idToken);
        if (role !== RoleEnum.None) {
          const path = getPathFromRole(role);
          return <Redirect noThrow to={`/${path}`} />;
        }
      }
      return <NotAuthorized />;
    }

    authService.login();
  } catch (e) {
    localStorage.clear();
    authService.login();
    return null;
  }
  return null;
};
