import * as React from "react";
import clsx from "clsx";
import { Link } from "@reach/router";
import { IconType } from "react-icons";

export enum ButtonVariant {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Fourth = "fourth",
  Fifth = "fifth",
  Sixth = "sixth"
}

export enum ButtonType {
  Button = "button",
  Submit = "submit",
}

export type ButtonProps = {
  icon?: IconType;
  variant: ButtonVariant;
  disabled?: boolean;
  to?: string;
  href?: string;
  target?: string;
  onClick?: (event: React.MouseEvent | React.FormEvent) => void;
  fullWidth?: boolean;
  withMargin?: boolean;
  type?: ButtonType;
  [key: string]: any;
  "data-analytics"?: string;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  icon: Icon,
  variant,
  disabled = false,
  to,
  href,
  target = "",
  onClick,
  type,
  fullWidth = false,
  withMargin = false,
  ...rest
}) => {
  const buttonClasses = clsx(
    "button button--full-width-mobile",
    variant === ButtonVariant.Primary && "button--solid",
    variant === ButtonVariant.Secondary && "button--ghost",
    variant === ButtonVariant.Tertiary && "button--ghost button--full-width-desktop",
    variant === ButtonVariant.Fourth && "button--solid button--full-width-desktop",
      variant === ButtonVariant.Fifth && "button--custom button--full-width-desktop",
      variant === ButtonVariant.Sixth && "button--custom-terug button--full-width-desktop",
    fullWidth && "button--full-width",
    disabled && "button--disabled",
    withMargin && "button--margin"
  );

  if (to && onClick) {
    return (
      <Link className={buttonClasses} onClick={onClick} to={to} {...rest}>
        {Icon && <Icon />} {children}
      </Link>
    );
  }
  else if (to) {
    return (
      <Link className={buttonClasses} to={to} {...rest}>
        {Icon && <Icon />} {children}
      </Link>
    );
  } else if (href) {
    return (
      <a className={buttonClasses} href={href} target={target || ""} {...rest}>
        {Icon && <Icon />} {children}
      </a>
    );
  }
  return (
    <button className={buttonClasses} disabled={disabled} onClick={onClick} type={type} {...rest}>
      {Icon && <Icon />} {children}
    </button>
  );
};
