export const nl_NL = {
  locale: "nl",
  messages: {
    "fields.validations.minLength": 'Vul minimaal 3 karakters in.',
    "fields.name": 'naam',
    "fields.birthday": 'geboortedatum',
    "fields.address": 'adres',
    "fields.policyNumber": 'polisnummer',
    "fields.email": 'e-mailadres',
    "fields.acceptTermsAndConditions.label":
      "Ik ben akkoord met de Algemene bepalingen, de polisvoorwaarden en het dienstverleningsdocument.",
    "fields.acceptTermsAndConditions.validations.required": "U dient de voorwaarden te accepteren.",
    "fields.street.label": "Straat",
    "fields.city.label": "Woonplaats",
    "fields.address.label": "Adres",
    "fields.documentType.label": "Document type",
    "fields.documentNumber.label": "Document nummer",
    "fields.documentNumber.validations.required": "Vul een geldig document nummer in",
    "fields.documentValidityDate.label": "Document geldigsheidsdatum",
    "fields.documentValidityDate.validations.required": "Vul een geldigsheidsdatum in.",
    "fields.documentValidityDate.validations.min": "Vul een geldigsheidsdatum in die in de toekomst ligt.",
    "fields.confirmationDocumentValidity.label": "Ik verklaar dat ik aan de hand van deze gegevens de identiteit heb geverifieerd van de verzekeringnemer.",
    "fields.confirmationDocumentValidity.validations.required": "U moet aangeven dat de ingevulde document gegevens correct zijn.",
    "fields.beneficiary.name.label": "Naam en voorletters",
    "fields.beneficiary.name.validations.required": "Vul de naam en voorletters in",
    "fields.beneficiary.name.validations.max": "De naam mag niet langer dan 100 tekens zijn",
    "fields.bsn.message": "Dit BSN-nummer hebben we nodig voor eventuele renseignering, en zal daarom ook alleen daarvoor gebruikt worden.",
    "fields.bsn.label": "Burgerservicenummer",
    "fields.bsn.validations.required": "Vul het burgerservicenummer in.",
    "fields.bsn.validations.isBSN": "Vul een geldig burgerservicenummer in.",
    "fields.dateOfBirth.label": "Geboortedatum",
    "fields.dateOfBirth.validations.date": "Vul een geldige datum in.",
    "fields.dateOfBirth.validations.min":
      "Helaas kunt u geen verzekering afsluiten voor personen jonger dan {min} jaar.",
    "fields.dateOfBirth.validations.max":
      "Helaas kunt u geen verzekering afsluiten voor personen ouder dan {max} jaar.",
    "fields.dateOfBirth.validations.required": "Vul de geboortedatum in.",
    "fields.emailAddress.label": "E-mail",
    "fields.emailAddress.validations.email": "Vul een geldig e-mailadres in.",
    "fields.emailAddress.validations.required": "Vul uw e-mailadres in.",
    "fields.firstName.label": "Voornaam",
    "fields.firstName.validations.required": "Vul de voornaam in.",
    "fields.firstName.validations.max": "De voornaam mag niet langer dan 100 tekens zijn.",
    "fields.gender.label": "Geslacht",
    "fields.gender.validations.required": "Selecteer uw geslacht",
    "fields.hasBeenDeclinedBefore.label": `Heeft een verzekeringsmaatschappij de verzekerde weleens
    een levens- of arbeidsongeschiktheidsverzekering geweigerd vanwege niet-medische redenen?`,
    "fields.hasBeenDeclinedBefore.explanation": `Antwoord met "ja" als de verzekerde de afgelopen
      drie jaar een specialist heeft bezocht. Dit kan zowel voor een controle als voor een
      behandeling zijn geweest.`,
    "fields.hasCondition.label":
      "Lijdt of leed de verzekerde aan een ziekte, aandoening of gebrek?",
    "fields.hasHadCanceledInsurance.label": `Heeft een verzekeringsmaatschappij weleens een
      levens- of arbeidsongeschiktheidsverzekering van de verzekerde opgezegd?`,
    "fields.hasHadPremiumIncrease.label": `Heeft een verzekeringsmaatschappij de verzekerde
      weleens verplicht een hogere premie of een slechtere dekking voor een levens- of
      arbeidsongeschiktheidsverzekering gegeven vanwege niet-medische redenen?`,
    "fields.hasVisitedSpecialist.label":
      "Is de verzekerde de laatste drie jaar bij een specialist geweest?",
    "fields.height.label": "Lengte in CM",
    "fields.height.validations.required": "Vul de lengte in.",
    "fields.height.validations.positive": "Vul een positieve waarde in voor de lengte.",
    "fields.height.validations.integer": "Vul de lengte in in hele centimeters.",
    "fields.height.validations.max": "U heeft een ongeldige lengte ingevuld.",
    "fields.houseNumber.label": "Huisnummer",
    "fields.houseNumber.validations.max": "Het huisnummer mag niet meer dan 10 tekens bevatten",
    "fields.houseNumber.validations.required": "Vul uw huisnummer in.",
    "fields.iban.label": "Rekeningnummer (IBAN)",
    "fields.iban.validations.isIBAN": "Het IBAN nummer is niet geldig.",
    "fields.iban.validations.required": "Vul het IBAN nummer in",
    "fields.ibanName.label": "Tenaamstelling",
    "fields.ibanName.validations.required": "Vul de naam van de rekeninghouder in",
    "fields.insuranceSubject.label": "Voor wie sluit u deze verzekering af?",
    "fields.insuranceSubject.option1.label": "Voor de klant",
    "fields.insuranceSubject.option2.label": "Voor iemand anders",
    "fields.insuredAmount.label": "Verzekerd bedrag (€)",
    "fields.insuredAmount.validations.general":
      "Vul het bedrag als geheel getal tussen de {min} en {max} in",
    "fields.insuredAmount.validations.ovr":
      "Vul het bedrag als geheel getal boven of gelijk aan het minimum {min}",
    "fields.insuredAmount.validations.required": "Vul het verzekerde bedrag in.",
    "fields.isUsingMeds.label": "Gebruikt de verzekerde medicijnen?",
    "fields.interestRate.label": "Rentepercentage lening",
    "fields.interestRate.validations.type": "De ingevulde rente is geen geldige numerieke waarde",
    "fields.interestRate.validations.positive": "De rente moet een positieve waarde zijn",
    "fields.lastName.label": "Achternaam",
    "fields.lastName.validations.required": "Vul de achternaam in.",
    "fields.lastName.validations.max": "De achternaam mag niet langer dan 100 tekens zijn.",
    "fields.lastNamePrefix.label": "Tussenvoegsel",
    "fields.lastNamePrefix.validations.max": "Het voorvoegsel mag niet langer dan 100 tekens zijn.",
    "fields.nonSmokingDeclaration.label":
      "deze tekst zal nog later bepaald worden",
      // "De verzekerde verklaart niet te roken en geen andere nicotinehoudende middelen te gebruiken en ook in de afgelopen 24 maanden niet te hebben gerookt en geen andere nicotinehoudende middelen te hebben gebruikt. De verzekerde verklaart Lifetri Verzekeringen meteen schriftelijk op de hoogte te brengen als hij (opnieuw) met roken is begonnen. Als na het overlijden van de verzekerde blijkt dat de verzekerde heeft gerookt of nicotinehoudende middelen heeft gebruikt of niet meteen Lifetri Verzekeringen op de hoogte heeft gebracht dat hij (opnieuw) met roken is begonnen, zal Lifetri Verzekeringen het verzekerde bedrag met 40% verlagen.",
    "fields.nonSmokingDeclaration.validations.required":
      "U bent nog niet akkoord gegaan met de niet-rokersverklaring.",
    "fields.otherInsurance.label":
      "Is in de afgelopen 3 jaar een verzekering met overlijdensrisico op het leven van deze verzekerde afgesloten of is (wordt) ergens anders ook een levensverzekering met overlijdensrisico aangevraagd?",
    "fields.otherInsurance.validations.required":
      "U moet aangeven of u elders een overlijdensrisicoverzekering heeft of heeft aangevraagd",
    "fields.otherInsuranceAmount.label": "Verzekerd bedrag",
    "fields.otherInsuranceAmount.validations.numeric":
      "Het verzekerd bedrag dient een positief, geheel getal te zijn.",
    "fields.otherInsuranceAmount.validations.required": "Het verzekerd bedrag is verplicht",
    "fields.name.label": "Naam",
    "fields.paymentFrequency.option.label": "Totale premie per {period}",
    "fields.permitMedicalDataUse.label":
      "Ik geef toestemming dat mijn medische gegevens gebruikt worden voor de aanvraag van deze verzekering",
    "fields.permitMedicalDataUse.validations.required":
      "U moet toestemming geven om de verzekering aan te vragen.",
    "fields.phoneNumber.label": "Telefoon",
    "fields.phoneNumber.validations.phoneNumberNL": "Vul een geldig telefoonnummer in.",
    "fields.phoneNumber.validations.required": "Vul uw telefoonnummer in.",
    "fields.policies.validations.required": "Selecteer minstens één polis",
    "fields.postalCode.label": "Postcode",
    "fields.postalCode.validations.postalCodeNL":
      "Deze postcode is niet geldig. Vul 4 cijfers en 2 letters in.",
    "fields.postalCode.validations.required": "Vul uw postcode in.",
    "fields.frequencyPer1.label": "Premie per maand",
    "fields.frequencyPer3.label": "Premie per kwartaal",
    "fields.frequencyPer12.label": "Premie per jaar",
    "fields.premiumSplit.label": "Premiesplitsing aantekenen",
    "fields.premium.normal.unchanged.label": "Gelijkblijvend",
    "fields.premium.normal.linear.label": "Lineair dalend",
    "fields.premium.normal.annuity.label": "Annuïtair dalend op basis van {interestRate}%",
    "fields.premium.noValueAccrual.unchanged.label": "Gelijkblijvend",
    "fields.premium.noValueAccrual.linear.label": "Lineair dalend",
    "fields.premium.noValueAccrual.annuity.label": "Annuïtair dalend op basis van {interestRate}%",
    "fields.premium.life.term.label": "(U betaalt {term} jaar premie)",
    "fields.premium.life.discount.label": "({discountPercentage}% korting)",
    "fields.premium.validations.required": "Selecteer een premie",
    "fields.premium.validations.typeError": "Selecteer een premie",
    "fields.mutation.title":"Mutatieverzoek",
    "fields.mutation.errorMessage":"Er is iets fout gegaan. Geef de wijzigingen door via klantenservice.orv@lifetri.nl",
    "fields.mutation.successMessage":"Mutatieverzoek verzonden.",
    "fields.request.validations.max": "Het mutatieverzoek mag maximaal 1000 tekens bevatten.",
    "fields.request.validations.incorrect":"U heeft karakters gebruikt, die niet zijn toegestaan",
    "fields.request.validations.required": "Vul een mutatieverzoek in.",
    "fields.runtime.label": "Looptijd (jaren)",
    "fields.runtime.validations.type": "De looptijd moet een positief getal zijn",
    "fields.runtime.validations.integer": "De looptijd moet een positief getal zijn",
    "fields.runtime.validations.positive": "De looptijd moet een positief getal zijn",
    "fields.runtime.validations.min": "De looptijd moet minimaal 5 jaar zijn",
    "fields.runtime.validations.max":
      "De looptijd moet uiterlijk eindigen op 75-jarige leeftijd van de oudste verzekerde",
    "fields.startDate.label": "Ingangsdatum",
    "fields.startDate.validations.required": "Vul een ingangsdatum in.",
    "fields.startDate.validations.min": "De verzekering kan niet eerder dan vandaag ingaan.",
    "fields.startDate.validations.max": "De verzekering mag maximaal maar 6 maanden in de toekomst starten.",
    "fields.weight.label": "Gewicht in KG",
    "fields.weight.validations.required": "Vul het gewicht in.",
    "fields.weight.validations.positive": "Vul een positieve waarde in voor het gewicht.",
    "fields.weight.validations.integer": "Vul het gewicht in in hele kilo's.",
    "fields.weight.validations.max": "U heeft een ongeldig gewicht ingevuld.",
    "generic.sortOn": 'Sorteren op',
    "generic.nextLabel": "Volgende",
    "generic.prevLabel": "Vorige",
    "generic.submitPolicyLabel": "Afsluiten",
    "generic.save.failed": "Er is iets mis gegaan. De wijzigingen zijn niet opgeslagen",
    "generic.save.success": "De wijzigingen zijn opgeslagen.",
    "generic.modal.close": "Sluiten",
    "policySummary.frequencyPer1.label": "maandelijks",
    "policySummary.frequencyPer3.label": "per kwartaal",
    "policySummary.frequencyPer6.label": "per half jaar",
    "policySummary.frequencyPer12.label": "jaarlijks",
    "insuredPersons.step.label": "Gegevens verzekerde",
    "insuredPersons.step1.label": "Gegevens verzekerde {index}",
    "insuredPersons.step2.label": "Verklaringen verzekerde {index}",
    "lifeInsurance.beneficiaryStep.title": "Begunstiging",
    "personalData.title": "Persoonlijke gegevens",
    "personalData.name.label": "Naam",
    "personalData.unknown": "Onbekend",
    "personalData.birthdate.label": "Geboortedatum",
    "personalData.address.label": "Adres",
    "personalData.city.label": "Woonplaats",
    "personalData.city.unknown": "Onbekend",
    "personalData.address.unknown": "Onbekend",
    "personalData.email.label": "E-mail",
    "personalData.email.unknown": "E-mailadres onbekend",
    "personalData.phoneNumber.label": "Telefoon",
    "personalData.phoneNumber.unknown": "Telefoonnummer onbekend",
    "personalData.editPersonalDataAsBroker.button": "Mutatie Basisgegevens",
    "personalData.editPersonalData.button": "Gegevens wijzigen",
    "personalData.editIBAN.button": "IBAN",
    "personalData.otherRequest.button": "Overige wijzigingen/vragen",
    "financial.accountNumber.label": "Rekeningnummer",
    "financial.balance.label": "Huidig saldo",
    "financial.date.label": "Datum",
    "financial.relation.number.label":"Relatie nummer",
    "financial.start.amount.label":"Begin saldo",
    "financial.end.amount.label":"Eind saldo",
    "general.month.january":"Januari",
    "general.month.february":"Februari",
    "general.month.march":"Maart",
    "general.month.april":"April",
    "general.month.may":"Mei",
    "general.month.june":"Juni",
    "general.month.july":"Juli",
    "general.month.august":"Augustus",
    "general.month.september":"September",
    "general.month.oktober":"Oktober",
    "general.month.november":"November",
    "general.month.december":"December",
    "general.month.unknown":"Onbekend",
    "navigation.topmenu.portefeuille": "Portefeuille",
    "navigation.topmenu.info": "Pensioenoverzicht",
    "navigation.topmenu.financial": "Financieel",
    "navigation.topmenu.profile": "Profiel",
    "info.no.message" : "Er zijn momenteel geen updates"
  },
};
