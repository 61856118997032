import * as React from "react";
import { PageWrapper } from "../../components/PageWrapper";
import { Helmet } from "react-helmet";
import { Card } from "../../components/Card";
import { FaLeaf } from "react-icons/fa";
import { Button, ButtonVariant } from "../../components/Button";
import { authService } from "../../lib/auth-service";

export type LoadingProps = {
  authIsPending?: boolean;
};

export const Loading: React.FC<LoadingProps> = ({ authIsPending }) => {
  const [showAuthMessage, setShowAuthMessage] = React.useState(false);

  React.useEffect(() => {
    if (authIsPending) {
      setTimeout(() => {
        setShowAuthMessage(true);
        restartAuth();
      }, 2000);
    }
  });

  const restartAuth = () => {
    localStorage.clear();
    authService.login();
  };

  return (
    <PageWrapper title="De pagina wordt geladen">
      <Helmet>
        <title>De pagina wordt geladen - Lifetri</title>
      </Helmet>
      <Card>
        <div className="row">
          <div className="col-md-12">
            <h2>De pagina wordt geladen...</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 offset-md-5">
            <FaLeaf className="loading__spinner" />
          </div>
        </div>
        <div className="row">
          {showAuthMessage && (
            <div className="col-12">
              <p>Inloggen mislukt. U wordt doorverbonden.</p>
              <Button variant={ButtonVariant.Fifth} onClick={restartAuth}>
                Probeer het nog een keer
              </Button>
            </div>
          )}
        </div>
      </Card>
    </PageWrapper>
  );
};
