import { AuthService } from "react-oauth2-pkce";

declare global {
  interface Window {
    _env_: any;
  }
}
window._env_ = window._env_ || {};

export const authService = new AuthService({
  clientId: window._env_.REACT_APP_ONEGINI_CLIENT_ID || "",
  location: window.location,
  provider: window._env_.REACT_APP_ONEGINI_OAUTH_PROVIDER || "",
  redirectUri: window._env_.REACT_APP_ONEGINI_REDIRECT_URL,
  scopes: [],
  autoRefresh: true,
}); 

/*export const authService = new AuthService({
  clientId: process.env.REACT_APP_ONEGINI_CLIENT_ID || "",
  location: window.location,
  provider: process.env.REACT_APP_ONEGINI_OAUTH_PROVIDER || "",
  redirectUri: process.env.REACT_APP_ONEGINI_REDIRECT_URL,
  scopes: [],
  autoRefresh: true,
});*/
