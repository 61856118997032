import React from "react";
import clsx from "clsx";
import {
  Accordion as ReachAccordion,
  AccordionItem as ReachAccordionItem,
  AccordionPanel as ReachAccordionPanel,
  AccordionButton as ReachAccordionButton,
  AccordionProps,
  AccordionItemProps,
  AccordionPanelProps,
  AccordionButtonProps,
} from "@reach/accordion";
import { FaChevronDown } from "react-icons/fa";
import {useTrackTabActionDetailsOpenCloseClick} from "../../hooks/google-tag-manager";

export const Accordion: React.FC<AccordionProps> = ({ children, className, ...accordionProps }) => {
  const classNames = clsx(className);

  return (
    // @ts-ignore
    <ReachAccordion
      multiple
      collapsible
      defaultIndex={0}
      className={classNames}
      {...accordionProps}
    >
      {children}
    </ReachAccordion>
  );
};

export const AccordionItem: React.FC<AccordionItemProps & { className?: string }> = ({
  children,
  className,
  ...accordionItemProps
}) => {
  const classNames = clsx("accordion", className);

  return (
    <ReachAccordionItem className={classNames} {...accordionItemProps}>
      {children}
    </ReachAccordionItem>
  );
};

export const AccordionPanel: React.FC<AccordionPanelProps & { className?: string }> = ({
  children,
  className,
  ...accordionPanelProps
}) => {
  const classNames = clsx("accordion__content", className);
  return (
    <ReachAccordionPanel className={classNames} {...accordionPanelProps}>
      {children}
    </ReachAccordionPanel>
  );
};

type ButtonProps = AccordionButtonProps & {
  className?: string;
  info?: string;
};

export const AccordionButton: React.FC<ButtonProps> = ({
  children,
  className,
  info,
  ...accordionButtonProps
}) => {
  const trackTabActionDetailsOpenCloseClick = useTrackTabActionDetailsOpenCloseClick();

  const classNames = clsx(
    "accordion__trigger d-flex align-items-center justify-content-between",
    className
  );

  const nullSafeInfo = info ? info : "";

  function sendGtmEvent() {
    if(isValidTab()) {
      trackTabActionDetailsOpenCloseClick({
        eventLabel: translateInfoToGtmIdentifier(),
      })
    }
  }

  function isValidTab() : boolean {
    const validInfo = clearWordOfData(nullSafeInfo);
    const words = ["policy-details", "transaction-details", "Polisnummer"]

    return words.includes(validInfo);
  }

  function translateInfoToGtmIdentifier() {
    const validInfo = clearWordOfData(nullSafeInfo);

    switch (validInfo) {
      case "policy-details":
        return "policy-details"
      case "transaction-details":
        return "transaction-details"
      case "Polisnummer":
        return "policy-details"
      default:
        return "unknown_tab"
    }
  }

  function clearWordOfData(word: string) : string {
    const polisNumber = "Polisnummer";
    let aWord = word;

    if(aWord.includes(polisNumber)) {
      aWord = aWord.substring(0, polisNumber.length)
    }
    return aWord;
  }

  return (
    <ReachAccordionButton as="div" className={classNames} {...accordionButtonProps}
                          onClick={() => sendGtmEvent()
                          }>
      <h3 className="accordion__trigger-title">{children}</h3>
      <div className="d-flex accordion__info-container">
        <p className="accordion__trigger-info">{info}</p>
        <FaChevronDown className="accordion__arrow" />
      </div>
    </ReachAccordionButton>
  );
};
