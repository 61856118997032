import * as React from "react";
import "whatwg-fetch";
import { ReactQueryDevtools } from "react-query/devtools";
import { Redirect, Router } from "@reach/router";
import { IntlProvider } from "react-intl";
import { CustomerRoutes } from "./routes/CustomerRoutes";
import {RoleEnum, userContext} from "./lib/user-context";
import { Helmet } from "react-helmet";
import Portal from "@reach/portal";
import { Alert } from "./components/Alert";
import { AlertContextProvider, AlertMessage } from "./lib/alert-context";
import {QueryClient, QueryClientProvider} from "react-query";
import { IconContext } from "react-icons";
import { nl_NL } from "./lib/i18n/nl-NL";
import { Authenticate } from "./Authenticate";
import { authService } from "./lib/auth-service";
import { AuthContext } from "react-oauth2-pkce";
import { CheckAuth } from "./components/CheckAuth";
import {Logout} from "./components/CheckAuth/Logout";
import { useInitializeTagManager } from "./hooks/google-tag-manager";
import {CookieBar} from "./components/CookieBar";
import {useContext} from "react";


export const App: React.FC = () => {
  const [alertMessage, setAlertMessage] = React.useState<AlertMessage>({ value: "" });
  const [data, setData] = React.useState({value:[]})
  const resetAlert = () => setAlertMessage({ value: "" });

  useInitializeTagManager();
  localStorageChecker();

  function localStorageChecker() {
    if((authService.getAuthTokens() as any).error) {
      localStorage.clear();
    }
  }

  const { user } = React.useContext(userContext);

  const {
    user: { id },
  } = useContext(userContext);

  function isValidCustomerId(id: string | undefined): id is string { return id !== undefined && id !== "" }

  let idStr = isValidCustomerId(id) ? id : "";

  const title = "Pensioenoverzicht";

  const queryClient = new QueryClient();

  return (
    <React.Suspense fallback="De gegevens worden geladen...">
      <AuthContext.Provider value={{ authService }}>
        <IntlProvider locale={nl_NL.locale} messages={nl_NL.messages}>
          <QueryClientProvider client={queryClient}>
            <IconContext.Provider value={{ className: "icon" }}>
              <AlertContextProvider value={{ setAlertMessage }}>
                <Helmet>
                  <link
                    href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&amp;display=swap"
                    rel="stylesheet"
                  />
                </Helmet>

                <Router>
                  <Authenticate path="/auth" />

                  <CheckAuth
                    authorizedRole={RoleEnum.Participant}
                    component={CustomerRoutes}
                    path="klant/*"
                  />

                  <Logout path="/logout"/>
                  <Redirect noThrow from="/" to="/auth" />
                </Router>

              </AlertContextProvider>
            </IconContext.Provider>
          </QueryClientProvider>
        </IntlProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <Portal>
          <Alert message={alertMessage.value} type={alertMessage.type} resetMessage={resetAlert} />
        </Portal>
      </AuthContext.Provider>
      <CookieBar />
    </React.Suspense>
  );
};
