import React from "react";

export type JumbotronProps = {
  title: string;
  subTitle?: string;
  showImage?: boolean;
};

export const Jumbotron: React.FC<JumbotronProps> = ({
  title,
  subTitle = "",
  showImage = false,
}) => (
  <section className="jumbotron jumbotron--overlap">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1 className="jumbotron__title jumbotron__title--small-mobile">{title}</h1>
          {subTitle !== "" && <p className="jumbotron__tagline">{subTitle}</p>}
        </div>
      </div>
    </div>
    {showImage && (
      <figure className="jumbotron__image">
        <img src="/images/content/background_nature.jpg" alt="" />
      </figure>
    )}
  </section>
);
