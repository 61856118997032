import React, { Fragment } from "react";
import { Header } from "../Header";
import { Jumbotron } from "../Jumbotron";

export type PageWrapperProps = {
  title: string;
  subTitle?: string;
  showImage?: boolean;
};

export const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  title,
  subTitle = "",
  showImage = false,
}) => (
  <Fragment>
    <Header />
    <Jumbotron showImage={showImage} title={title} subTitle={subTitle} />
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12">{children}</div>
        </div>
      </div>
    </section>
  </Fragment>
);
