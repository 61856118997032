import * as React from 'react';
import {useQueries} from 'react-query';
import {RouteComponentProps} from "@reach/router";
import {PageWrapper} from "../../components/PageWrapper";
import {Card} from "../../components/Card";
import {downloadPensioenDocumentAsPdf, getParticipantInfo, getUpoList} from "@lib/queries";
import {Loading} from "../../views/Loading";
import {DefinitionList} from "../../components/DefinitionList";
import {dateISO2NL} from "@lib/date-converters";
import {FaFilePdf} from 'react-icons/fa';
import {Accordion, AccordionItem, AccordionPanel} from "../../components/Accordion";

export interface UpoListPageProps extends RouteComponentProps {
}

export const UpoListPage: React.FC<UpoListPageProps> = () => {
    const results = useQueries([
        {queryKey: 'participant', queryFn: getParticipantInfo},
        {queryKey: 'upoList', queryFn: getUpoList}
    ]);
    const isLoading = results.some(result => result.isLoading);

    if (isLoading) {
        return <Loading/>
    }

    const participant = results[0].data;
    const participantData = participant ? [
        {
            key: 'naam',
            value: `${participant.initials || ""} ${participant.preposition || ""} ${participant.lastName || ""}`,
        },
        {
            key: 'geboortedatum',
            value: dateISO2NL(participant.dateOfBirth)
        }
    ] : [];

    const upoDocs = results[1].data?.documents || [];

    const downloadPdf = (archiveId: string, documentId: string, fileName: string) => {
        downloadPensioenDocumentAsPdf(archiveId, documentId, fileName);
    }

    return (
        <PageWrapper showImage title={"Pensioenoverzichten"}>
            <Card>
                <div className="row">
                    <div className="col-md-12">
                        <h2>Persoonlijke gegevens</h2>
                    </div>
                </div>
                {results[0].isError
                    ? <p className="error">Er is een fout opgetreden bij het ophalen van uw gegevens.</p>
                    : <DefinitionList data={participantData}/>
                }
                <div className="row">
                    <div className="col-md-12">
                        <h2>Documenten</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Accordion collapsible={false} defaultIndex={-1}>
                            <AccordionItem>
                                <AccordionPanel>
                                    {results[1].isError &&
                                        <p className="error">Er is een fout opgetreden bij het ophalen van uw
                                            documenten.</p>
                                    }
                                    {upoDocs.length === 0 && <p>Er zijn geen documenten gevonden.</p>}
                                    {upoDocs.length > 0 && <ul className="list">
                                        {upoDocs.map(doc => {
                                            const upoYear = new Date(doc.date).getFullYear();
                                            return (
                                                <li key={doc.documentId} className="list__item">
                                                    <a className="text-link text-link--base"
                                                       onClick={() => downloadPdf(doc.archiveId, doc.documentId, `UPO-${upoYear}`)}>
                                                        <FaFilePdf className="text-link__icon"/> UPO Document
                                                        - {upoYear}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>}
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>

            </Card>
        </PageWrapper>
    );
}
