import React from "react";
import image from "./lifetri_logo.svg";

export const Logo: React.FC = () => {
  return (
    <a className="header__home-link" href="/">
      <img src={image} alt="lifetri logo" className="header__logo" />
    </a>
  );
};
