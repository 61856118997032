import React from "react";
import {RouteComponentProps} from "@reach/router";
import {PageWrapper} from "../PageWrapper";
import {NotAuthorized} from "./NotAuthorized";
import {authService} from "../../lib/auth-service";
import {Helmet} from "react-helmet";
import { Card } from "../Card";


export const Logout: React.FC<RouteComponentProps> = () => {

  const baseUrl = `${window._env_.REACT_APP_ONEGINI_OAUTH_PROVIDER}/logout?id_token_hint=`;
  //const baseUrl = `${process.env.REACT_APP_ONEGINI_OAUTH_PROVIDER}/logout?id_token_hint=`;

  const authData = window.localStorage.getItem("auth");

  if (authData) {
    const authJson = JSON.parse(authData);

    if (authJson) {

      let idToken = authJson.id_token;

      authService.logout();
      return (
        <PageWrapper title="Uitloggen">
          <Helmet>
            <title>Uitloggen - Lifetri</title>
          </Helmet>
          <Card>
            <div className="row">
              <div className="col-md-12">
                <h2>U wordt uitgelogd.</h2>
              </div>
            </div>
          </Card>
          <div className="no-display">
          { window.location.href = `${baseUrl}${idToken}` }
          </div>
        </PageWrapper>
      );
    }
  }
  return (
    <NotAuthorized />
  );
}

