import React from "react";

export type IdToken = {
    roles: string;
    scope: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
    nickname: string;
}

export const idTokenContext = React.createContext<IdToken>({
    roles: "",
    scope: "",
    preferred_username: "",
    given_name: "",
    family_name: "",
    nickname: ""
});

export const IdTokenProvider = idTokenContext.Provider;
