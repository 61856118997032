import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import clsx from "clsx";
import {useTrackAlertMessages} from "../../hooks/google-tag-manager";

export type AlertProps = {
  message: string;
  type?: string;
  duration?: number;
  resetMessage: () => void;
};
export const Alert: React.FC<AlertProps> = ({ message, type = "success",
                                              duration = 5000, resetMessage }) => {
  const [isVisible, setIsVisible] = useState(false);

  const trackNotificationMessage = useTrackAlertMessages();

  useEffect(() => {
    if (message.length > 0) {
      setIsVisible(true);
      trackNotificationMessage( {
        eventLabel: type
      })
      setTimeout(() => {
        resetMessage();
        setIsVisible(false);
      }, duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <div className={clsx("alert", isVisible ? "d-block" : "d-none",
      type === 'error' ? 'error' : '',
      type === 'partial_error' ? 'partial_error' : '')}>
      <FaCheckCircle className="icon" /> {message}
    </div>
  );
};
