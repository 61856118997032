import * as React from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";

import { useTrackPageView } from "../hooks/google-tag-manager";
import {UpoListPage} from "../pages/UpoListPage";

export const CustomerRoutes: React.FC<RouteComponentProps> = () => {
    useTrackPageView();

    return (
        <Router>
            <Redirect noThrow from="/" to="pensioen-overzichten" />
            <UpoListPage path="/pensioen-overzichten" />
        </Router>
    );
};


