import React from "react";
import { useAuth } from "react-oauth2-pkce";
import { RouteComponentProps } from "@reach/router";
import { PageWrapper } from "../PageWrapper";
import { Helmet } from "react-helmet";
import { Card } from "../Card";
import { Button, ButtonVariant } from "../Button";

export const NotAuthorized: React.FC<RouteComponentProps> = () => {
  const { authService } = useAuth();

  const login = () => {
    authService.login();
  };

  return (
    <PageWrapper title="Geen toegang">
      <Helmet>
        <title>Geen toegang - Lifetri</title>
      </Helmet>
      <Card>
        <div className="row">
          <div className="col-md-12">
            <h2>Geen toestemming om pagina te openen</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p>U bent niet ingelogd, of u heeft geen toestemming om deze pagina te openen.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Button variant={ButtonVariant.Primary} onClick={login}>
              Inloggen
            </Button>
          </div>
        </div>
      </Card>
    </PageWrapper>
  );
};
