import {
    Participant, XtendisDocumentInfo,
} from "../types/domain";
import {QueryFunction} from "react-query";
import {Fetch, Fetcher} from "./fetch";
import jwt from "jsonwebtoken";

export const getBaseUrl = (): string => `${window._env_.API_SERVER}/pension/api/v1`

export const addAccessTokenInterceptor = (fetch: Fetch) => (
    url: RequestInfo,
    options: RequestInit = {}
) => {
    const authData = window.localStorage.getItem("auth");
    if (authData) {
        const authJson = JSON.parse(authData);
        if (authJson) {
            const idToken = authJson.id_token;
            const authOptions = {
                ...options,
                headers: {
                    ...options.headers,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${idToken}`,
                },
            };

            return fetch.apply(null, [url, authOptions]);
        }
    }
    return fetch.apply(null, [url, options]);
};

export const downloadPensioenDocumentAsPdf = async (
    archiveId: string,
    documentId: string,
    fileName: string,
) => {
    const fetcher = new Fetcher(`${getBaseUrl()}/documents/${archiveId}/${documentId}`);
    fetcher.addRequestInterceptor(addAccessTokenInterceptor);
    const res = await fetcher.execute();
    if (res.ok) {
        const pdfBlob = await res.blob();
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = fileName;
        link.click();
    }
};

export const getParticipantInfo: QueryFunction<Participant, string> = async (ctx) => {
    const fetcher = new Fetcher(`${getBaseUrl()}/participants/information`);
    fetcher.addRequestInterceptor(addAccessTokenInterceptor);
    const response = await fetcher.execute();
    if (response.ok) {
        return await response.json() as Promise<Participant>;
    } else {
        throw new Error("Invalid response");
    }
};

export interface GetUpoListResponse {
    documents: Array<XtendisDocumentInfo>
}

export const getUpoList: QueryFunction<GetUpoListResponse, string> = async (ctx) => {
    const fetcher = new Fetcher(`${getBaseUrl()}/documents/upo`);
    fetcher.addRequestInterceptor(addAccessTokenInterceptor);
    const response = await fetcher.execute();
    if (response.ok) {
        return response.json() as Promise<GetUpoListResponse>;
    } else {
        throw new Error("Invalid response");
    }
}
