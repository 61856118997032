export const  fixPathname = (pathname: string) => {

  if(pathname.includes("/auth")) {
    const position = pathname.indexOf("?")
    pathname = pathname.substring(0, position)
  }

  if(pathname.includes("/klant-details/")) {
    const word = "/klant-details/"
    const position = pathname.indexOf(word)
    pathname = pathname.substring(0, position + word.length) + "REDACTED"
  }
  return pathname;
}
