import { authService } from "../lib/auth-service";
import { useLocation } from "@reach/router";
import * as React from "react";

// @ts-ignore
import TagManager from "react-gtm-module-nonce";
import {fixPathname} from "../lib/remove-pi-from-path";

interface User {
  roles: string;
  attachedTo: string;
}

export enum GtmEvent {
  PageView = "VirtualPageView",
  Click = "click",
  Notification = "notification",
}

interface EventData {
  event: GtmEvent;
  eventCategory?: string;
  eventAction?: string;
}

const useBaseDataLayer = () => {
  const baseDataLayer: { visitorType?: string; broker_id?: string } = {
    visitorType: undefined,
    broker_id: undefined,
  };
  if (authService.isAuthenticated()) {
    const user = authService.getUser() as User;
    baseDataLayer.visitorType = user.roles;
    baseDataLayer.broker_id = user.attachedTo ? user.attachedTo : "";
  }
  return baseDataLayer;
};

export const useInitializeTagManager = () => {
  React.useEffect(() => {

    if (window._env_.REACT_APP_GTM_ID) {
      const tagManagerArgs = {
        gtmId: window._env_.REACT_APP_GTM_ID,
        nonce: window._env_.REACT_APP_GTM_NONCE,
      };
      TagManager.initialize(tagManagerArgs);
    } 

   /* if (process.env.REACT_APP_GTM_ID) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        nonce: process.env.REACT_APP_GTM_NONCE,
      };
      TagManager.initialize(tagManagerArgs);
    }*/


  }, []);
};

export const useTrackEvent = ({ event, eventCategory, eventAction }: EventData) => {
  const baseDataLayer = useBaseDataLayer();

  return (data?: Record<string, unknown>) => {
    TagManager.dataLayer({
      dataLayer: {
        ...baseDataLayer,
        event,
        eventCategory,
        eventAction,
        ...data,
      },
    });
  };
};

/** Google Events **/
export const useTrackPageView = () => {
  const location = useLocation();

  const pathname = fixPathname(location.pathname);

  const trackPageView = useTrackEvent({
    event: GtmEvent.PageView,
  });

  React.useEffect(() => {
    trackPageView({ page: pathname, eventLabel: undefined});
  }, [location]);
};

export const useTrackCookieButtonClick = () => {
  return useTrackEvent({
    event: GtmEvent.Click,
    eventCategory: "buttonClick",
    eventAction: "cookie_notification"
  });
};

export const useTrackCustomerBackClick = () => {
  return useTrackEvent( {
    event: GtmEvent.Click,
    eventCategory: "buttonClick",
    eventAction: "back_to_search"
  })
}

export const useTrackCustomerRowClick = () => {
  return useTrackEvent({
    event: GtmEvent.Click,
    eventCategory: "customer-search",
    eventAction: "select customer",
  });
};

export const useTrackTableSortClick = () => {
  return useTrackEvent({
    event: GtmEvent.Click,
    eventCategory: "customer-search-sort",
  });
};

export const useTrackTopMenuClick = () => {
  return useTrackEvent({
    event: GtmEvent.Click,
    eventCategory: "navigation",
    eventAction: "menu-item"
  });
};

export const useTrackFinancialSubMenuClick = () => {
  return useTrackEvent({
    event: GtmEvent.Click,
    eventCategory: "navigation",
    eventAction: "submenu-item"
  });
};

export const useTrackEditCustomerDataButtonClick = () => {
  return useTrackEvent( {
    event: GtmEvent.Click,
    eventCategory: "buttonClick",
    eventAction: "edit_customer_table"
  });
};

export const useTrackCancelCustomerDataButtonClick = () => {
  return useTrackEvent( {
    event: GtmEvent.Click,
    eventCategory: "buttonClick",
    eventAction: "cancel_mutation"
  });
};

export const useTrackTabActionDetailsOpenCloseClick = () => {
  return useTrackEvent({
    event: GtmEvent.Click,
    eventCategory: "tabClick",
    eventAction: "open_close_tab"
  });
};

export const useTrackDownloadFiles = () => {
  return useTrackEvent({
    event: GtmEvent.Click,
    eventCategory: "open",
    eventAction: "open_document"
  });
};

export const useTrackAlertMessages = () => {
  return useTrackEvent({
    event: GtmEvent.Notification,
    eventCategory: "notification",
    eventAction: "submit"
  })
}
