import * as React from "react";

type AlertContext = {
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
};

export type AlertMessage = {
  value: string;
  type?: string;
}

export const alertContext = React.createContext({
  setAlertMessage: (_value: AlertMessage) => {},
});

export const AlertContextProvider = alertContext.Provider;
