import React, {useState} from "react";
import clsx from "clsx";
import {authService} from "../../lib/auth-service";
import {useTrackCookieButtonClick} from "../../hooks/google-tag-manager";

export const CookieBar = () => {

  const cookieName = "cookieAccepted";
  const [isVisible, setIsVisible] = useState(showCookieBar());
  const trackCookieButtonClick = useTrackCookieButtonClick();


  function onClick() {
    const date = new Date();
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

    const value = "true";
    document.cookie = cookieName+"="+value+"; expires="+date.toUTCString()+"; path=/";
    setIsVisible(false);

    trackCookieButtonClick({
      eventLabel: "close-notification"
    });

  }

  function showCookieBar() {
    const value = "; " + document.cookie;
    const parts = value.split("; " + cookieName + "=");

    if (parts.length == 2) {
      if(parts.pop()?.split(";").shift()) {
        return (parts.pop()?.split(";").shift() === "false");
      }
    }

    // We  don't want to show the cookie-bar during the redirect to the login-screen.
    return authService.isAuthenticated();

  }

  return (
    <div className={clsx ("ltri-cookie-notice", isVisible? "ltri-cookie-notice--visible" : "")}>
      <h3 className="ltri-cookie-notice__title">Deze website maakt gebruik van cookies</h3>
      <div>
        Wij maken gebruik van functionele cookies om de website goed te laten functioneren en wij gebruiken analytische
        cookies om je een optimale gebruikservaring te bieden. De analytische cookies zijn privacy vriendelijk
        ingesteld. Wij maken geen gebruik van marketing cookies of tracking cookies. Kijk voor meer informatie over
        welke cookies wij plaatsen in de cookieverklaring.
      </div>
      <div className="ltri-cookie-notice__actions">
        <a href={"https://lifetri.nl/cookies/"} target="_blank"
           onClick={() => trackCookieButtonClick({
             eventLabel: "more-information"
           })}>
          Meer informatie
        </a>
        <button className="ltri-button" onClick={onClick}>Melding sluiten</button>
      </div>
    </div>
  );
};
