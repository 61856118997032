import * as React from "react";
import clsx from "clsx";

export enum CardVariant {
  Default = "default",
  Modal = "modal",
  Small = "small",
  NoPadding = "noPadding",
}

export type CardProps = {
  variant?: CardVariant;
};

export const Card: React.FC<CardProps> = ({ children, variant = CardVariant.Default }) => {
  const cardClasses = clsx(
    "card card--white",
    variant === CardVariant.Small && "card--small",
    variant === CardVariant.Modal && "modal__card",
    variant === CardVariant.Default && "card--padding"
  );

  return <div className={cardClasses}>{children}</div>;
};
