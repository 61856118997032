import { IdToken } from "./auth-context";
import { RoleEnum } from "./user-context";

export const getRoleFromToken = (idToken: IdToken) => {
  if (!idToken) {
    return RoleEnum.None;
  }

  let tokenString = "";

  let rolesInfoAvailable =  true;
  let scopeInfoAvailable = true;

  if(!idToken.roles || idToken.roles === "") {
    rolesInfoAvailable = false;
  }

  if(!idToken.scope || idToken.roles === "") {
    scopeInfoAvailable =   false;
  }

  if(rolesInfoAvailable || scopeInfoAvailable) {
    tokenString = idToken.roles ? idToken.roles : idToken.scope;
  }

  switch (tokenString.toLowerCase()) {
    case "participant":
      return RoleEnum.Participant;
    case "customer":
      return RoleEnum.Customer;
    default:
      return RoleEnum.None;
  }
};
