export enum Method {
  "Get" = "GET",
  "Post" = "POST",
  "Put" = "PUT",
  "Delete" = "DELETE",
}
export type FetcherOptions = {
  method?: Method;
  body?: string;
};

export type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>;
export type RequestInterceptor = (
  fetch: Fetch
) => (input: RequestInfo, init?: RequestInit) => Promise<Response>;

export class Fetcher {
  url: string;
  options: FetcherOptions;

  private requestInterceptors: RequestInterceptor[] = [];

  private defaultOptions: FetcherOptions = {
    method: Method.Get,
  };
  constructor(url: string, options: FetcherOptions = {}) {
    this.url = url;
    this.options = {
      ...this.defaultOptions,
      ...options,
    };
  }

  addRequestInterceptor(interceptorFn: RequestInterceptor): void {
    this.requestInterceptors.push(interceptorFn);
  }

  execute(): Promise<Response> {
    const interceptedFetch = this.requestInterceptors.reduce((originalFetch, interceptor) => {
      return interceptor(originalFetch);
    }, fetch as Fetch);

    return interceptedFetch(this.url, this.options);
  }
}
