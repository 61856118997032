import parse from "date-fns/parse";
import isValid from "date-fns/isValid";
import nl from "date-fns/locale/nl";
import format from "date-fns/format";

export const dateISO2NL = (date: string) => {
  if (date) {
    const parsedDate = parse(date, "yyyy-MM-dd", new Date(), { locale: nl });
    if (!isValid(parsedDate)) {
      return "";
    }
    return format(parsedDate, "dd-MM-yyyy");
  }
  return "";
};

export const dateNL2ISO = (date: string) => {
  if (date) {
    const parsedDate = parse(date, "dd-MM-yyyy", new Date(), { locale: nl });
    if (!isValid(parsedDate)) {
      return date;
    }
    return format(parsedDate, "yyyy-MM-dd");
  }
  return date;
};

/**
 * Returns the name of asked month.
 *
 * @param date date in string format (yyyy-MM-dd)
 * @param forwardBack amount of months back or forward from given month.
 */
export const dateToMonthName = (date: string, forwardBack: number) => {
  const MONTHS_IN_YEAR = 12;

  const parsedDate = parse(date, "yyyy-MM-dd", new Date(), { locale: nl });
  if (!isValid(parsedDate)) {
    return "general.month.unknown";
  }

  let monthNumber : number = parsedDate.getMonth() + forwardBack;
  while (monthNumber < 0 || monthNumber > 11) {
    if(monthNumber < 0) {
      monthNumber = MONTHS_IN_YEAR - monthNumber;
    } else if(monthNumber > 11) {
      monthNumber =  monthNumber - MONTHS_IN_YEAR
    }
  }

  return monthNumberToName(monthNumber);
}

function monthNumberToName(monthNumber: number) {
  switch(monthNumber) {
    case 0 :return "general.month.january";
    case 1: return "general.month.february";
    case 2: return "general.month.march";
    case 3: return "general.month.april";
    case 4: return "general.month.may";
    case 5: return "general.month.june";
    case 6: return "general.month.july";
    case 7: return "general.month.august";
    case 8: return "general.month.september";
    case 9: return "general.month.oktober";
    case 10: return "general.month.november";
    case 11: return "general.month.december";

    default: return "general.month.unknown";
  }
}
