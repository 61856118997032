import * as React from "react";

export enum RoleEnum {
  None = "none",
  Customer = "customer",
  Participant = "participant"
}

export type UserContext = {
  user: {
    id?: string;
    isLoggedIn: boolean;
    role: RoleEnum;
    userName?: string;
    emailAddress?: string;
  };
};

export const userContext = React.createContext<UserContext>({
  user: {
    isLoggedIn: false,
    role: RoleEnum.None,
  },
});

export const UserContextProvider = userContext.Provider;
